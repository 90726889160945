import type {
  CompanyApiKeyDto,
  CompanyCreditsDto,
  CompanyDto,
  CompanyProjectDto,
  ProjectDeploymentStatsDto,
  UserDto,
} from "~/server/serializers";

export const useUser = () => useState<UserDto | null>("user", () => null);
export const useCompanies = () => useState<CompanyDto[]>("companies", () => []);
export const useCompany = () => useState<CompanyDto | null>("company", () => null);
export const useCompanyApiKeys = () => useState<CompanyApiKeyDto[]>("company-api-keys", () => []);
export const useCompanyCredits = () => useState<CompanyCreditsDto | null>("company-credits", () => null);
export const useCompanyProjects = () => useState<CompanyProjectDto[]>("company-projects", () => []);
export const useDeploymentStats = () => useState<ProjectDeploymentStatsDto | null>("deployment-stats", () => null);

export const refreshUser = async () => {
  const user = useUser();
  const { data: userData } = await api_get_user();
  user.value = unref(userData);

  return user;
};

export const refreshUserData = async () => {
  const companies = await refreshCompanies();

  if (companies.value.length > 0) {
    await refreshCompanyCredits();
    await refreshCompanyProjects();
  }

  return companies.value.length > 0;
};

export const refreshCompanies = async () => {
  const companies = useCompanies();
  const { data: companiesData } = await api_get_companies();
  companies.value = unref(companiesData) || [];

  if (companies.value.length > 0) {
    await refreshCompany();
  }

  return companies;
};

export const refreshCompany = async () => {
  const companies = useCompanies();
  const company = useCompany();
  const companyCookie = useCookie("current-company", { maxAge: 60 * 60 * 24 * 30 * 3 });

  const company_slug = useRoute().params.company_slug || String(companyCookie.value);
  company.value = companies.value.find(({ slug }) => slug === company_slug) || companies.value[0];
  companyCookie.value = company.value.slug;

  return company;
};

export const refreshCompanyApiKeys = async () => {
  const company = useCompany();
  if (!company.value) return;

  const apiKeys = useCompanyApiKeys();
  const { data: apiKeysData } = await api_get_company_api_keys(company.value.id);
  apiKeys.value = unref(apiKeysData) || [];

  return apiKeys;
};

export const refreshCompanyCredits = async () => {
  const company = useCompany();
  if (!company.value) return;

  const credits = useCompanyCredits();
  const { data: creditsData } = await api_get_company_credits(company.value.id);
  credits.value = unref(creditsData);

  return credits;
};

export const refreshCompanyProjects = async () => {
  const company = useCompany();
  if (!company.value) return;

  const projects = useCompanyProjects();
  const { data: projectsData } = await api_get_company_projects(company.value.id);
  projects.value = unref(projectsData) || [];

  return projects;
};

export const refreshDeploymentStats = async (period_days: number, company_id: string, project_id?: string) => {
  const stats = useDeploymentStats();
  const { data: statsData } = await api_get_deployment_stats(period_days, company_id, project_id);
  stats.value = unref(statsData);

  return stats;
};

export function useAuthenticated<T>(composable: () => Ref<T | null>) {
  return () => {
    const data = composable();
    return computed(() => {
      const dataValue = unref(data);
      if (!dataValue) {
        throw createError("composable can only be used in protected pages");
      }
      return dataValue;
    });
  };
}

export function useLocalStorage<T>(key: string, init?: () => T | Ref<T>) {
  const state = useState<T>(key, init);

  if (import.meta.browser) {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      const value = JSON.parse(storedValue);
      state.value = typeof value === "object" ? { ...state.value, ...value } : value;
    }

    watch(state, () => {
      localStorage.setItem(key, JSON.stringify(state.value));
    });
  }

  return state;
}
