import revive_payload_client_2ST8WluUND from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YumLbYfwjf from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ji5zTFeQWh from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_e2HIAK7Krk from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.7_rollup@4.17.2_vite@5.3.2_@types+node@22.5.1_ters_kyaucrjw3mhfgnqmqiphn36o64/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_m3qlIvZqFr from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BJgMXdfKEB from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_udg0zbc3D3 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_1Yd6mWfbD4 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._wrcbbmvu2jli2fb6lc3vyvpoyq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_client_sQhJOBiqXf from "/app/plugins/error-handler.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_2ST8WluUND,
  unhead_YumLbYfwjf,
  router_ji5zTFeQWh,
  _0_siteConfig_e2HIAK7Krk,
  payload_client_m3qlIvZqFr,
  check_outdated_build_client_BJgMXdfKEB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_udg0zbc3D3,
  chunk_reload_client_1Yd6mWfbD4,
  error_handler_client_sQhJOBiqXf,
  sentry_client_shVUlIjFLk
]