import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    app: { sentry },
  } = useRuntimeConfig();

  const enabled = sentry.enabled && sentry.dsn;

  if (enabled) {
    const router = useRouter();

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracePropagationTargets: ["localhost", "https://otago.dev"],
      tracesSampleRate: sentry.environment === "production" ? 0.2 : 1.0,
      replaysSessionSampleRate: sentry.environment === "production" ? 0.5 : 1.0,
      replaysOnErrorSampleRate: sentry.environment === "production" ? 0.5 : 1.0,
    });
  }

  return {
    provide: {
      setSentryUser: (user_id: string) => {
        if (enabled) Sentry.setUser({ id: user_id });
      },
      captureSentryException: (...params: Parameters<typeof Sentry.captureException>) => {
        console.error(...params);
        if (enabled) Sentry.captureException(...params);
      },
    },
  };
});
