import { defineNuxtPlugin } from '#app/nuxt'
import { LazyComponentsEmailLayout, LazyContactForm, LazyFirstInstallSuccess, LazyLowCredits, LazyNewApiKey, LazyNewBetatestCredits, LazyNewCredits, LazyNoCredits, LazyPaymentFailed, LazySendInvitation } from '#components'
const lazyGlobalComponents = [
  ["ComponentsEmailLayout", LazyComponentsEmailLayout],
["ContactForm", LazyContactForm],
["FirstInstallSuccess", LazyFirstInstallSuccess],
["LowCredits", LazyLowCredits],
["NewApiKey", LazyNewApiKey],
["NewBetatestCredits", LazyNewBetatestCredits],
["NewCredits", LazyNewCredits],
["NoCredits", LazyNoCredits],
["PaymentFailed", LazyPaymentFailed],
["SendInvitation", LazySendInvitation],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
