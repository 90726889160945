// XXX: should already be done here: https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/plugins/chunk-reload.client.ts
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:chunkError", (error) => {
    console.error("app:chunkError", String(error));
    clearError();
    window.location.reload();
  });

  nuxtApp.hook("app:error", (error) => {
    const reoload_error_list = [
      "error loading dynamically imported module",
      "Importing a module script failed",
      "Failed to fetch dynamically imported module",
      "Unable to preload",
    ];
    if (new RegExp(reoload_error_list.join("|"), "i").test(error.message)) {
      console.error("app:error", String(error));
      clearError();
      window.location.reload();
    } else {
      useNuxtApp().$captureSentryException(error);
    }
  });
});
