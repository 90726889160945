export const api_get_user = async () => {
  return useFetch("/auth/user");
};

export const api_get_companies = async () => {
  return useFetch("/api/companies");
};

export const api_get_company_api_keys = async (company_id: string) => {
  return useFetch(`/api/companies/${company_id}/api_keys`);
};

export const api_get_company_credits = async (company_id: string) => {
  return useFetch(`/api/companies/${company_id}/credits`);
};

export const api_get_company_credit_automation = async (company_id: string) => {
  return useFetch(`/api/companies/${company_id}/credit_automation`);
};

export const api_get_company_members = async (company_id: string) => {
  return useFetch(`/api/companies/${company_id}/members`);
};

export const api_get_company_projects = async (company_id: string) => {
  return useFetch(`/api/companies/${company_id}/projects`);
};

export const api_get_project_deployments = async (company_id: string, project_id: string) => {
  return useFetch(`/api/companies/${company_id}/projects/${project_id}/deployments`);
};

export const api_get_project_last_deployment = async (company_id: string, project_id: string) => {
  return useFetch(`/api/companies/${company_id}/projects/${project_id}/last_deployment`);
};

export const api_get_deployment_stats = async (period_days: number, company_id: string, project_id?: string) => {
  return useFetch(`/api/companies/${company_id}/deployment_stats`, {
    query: { company_project_id: project_id, period_days },
  });
};

export const api_post_company_api_key = async (
  company_id: string,
  { name, scopes, expires_at }: { name: string; scopes: string[]; expires_at: string | null },
) => {
  return useFetch(`/api/companies/${company_id}/api_keys`, {
    method: "POST",
    body: JSON.stringify({ name, scopes, expires_at }),
  });
};

export const api_post_company_project = async (
  company_id: string,
  { name, env }: { name: string; env: string | null },
) => {
  return useFetch(`/api/companies/${company_id}/projects`, {
    method: "POST",
    body: JSON.stringify({ name, env }),
  });
};

export const api_post_company_member = async (company_id: string, { email }: { email: string }) => {
  return useFetch(`/api/companies/${company_id}/members`, {
    method: "POST",
    body: JSON.stringify({ email }),
  });
};

export const api_post_company_credit_invoice = async (company_id: string, amount: number) => {
  return useFetch(`/api/companies/${company_id}/credits/invoice`, {
    method: "POST",
    body: JSON.stringify({ amount }),
  });
};

export const api_post_company_credit_automation = async (
  company_id: string,
  { install_limit, action, refill_cents }: { install_limit: number; action: string; refill_cents?: number },
) => {
  return useFetch(`/api/companies/${company_id}/credit_automation`, {
    method: "POST",
    body: JSON.stringify({ install_limit, action, refill_cents }),
  });
};

export const api_post_contact = async (params: {
  name: string;
  company: string;
  email: string;
  phone: string;
  message: string;
  recaptcha: string;
}) => {
  return useFetch("/contact", { method: "POST", body: JSON.stringify(params) });
};

export const api_patch_company = async (company_id: string, params: Record<string, unknown>) => {
  return useFetch(`/api/companies/${company_id}`, {
    method: "PATCH",
    body: JSON.stringify(params),
  });
};

export const api_delete_company_api_key = async (company_id: string, api_key_id: string) => {
  return useFetch(`/api/companies/${company_id}/api_keys/${api_key_id}`, {
    method: "DELETE",
  });
};

export const api_delete_company_member = async (company_id: string, member_id: string) => {
  return useFetch(`/api/companies/${company_id}/members/${member_id}`, {
    method: "DELETE",
  });
};

type ServerEventsCallback = (event_name: string, payload: object) => unknown;

const on_server_event = (eventStreamUrl: string, callback: ServerEventsCallback) => {
  if (!import.meta.browser) return;

  const evtSource = new EventSource(eventStreamUrl);

  evtSource.addEventListener("message", (event) => {
    const { event_name, payload } = JSON.parse(event.data);
    callback(event_name, payload);
  });

  evtSource.addEventListener("error", (event) => {
    console.log("Event source error", String(event));
  });

  return evtSource;
};

export const on_company_server_event = (company_id: string, callback: ServerEventsCallback) => {
  return on_server_event(`/api/companies/${company_id}/server-events`, callback);
};

export const on_project_server_event = (company_id: string, project_id: string, callback: ServerEventsCallback) => {
  return on_server_event(`/api/companies/${company_id}/server-events?project_id=${project_id}&`, callback);
};

export const on_deployment_server_event = (
  company_id: string,
  project_id: string,
  deployment_id: string,
  callback: ServerEventsCallback,
) => {
  return on_server_event(
    `/api/companies/${company_id}/server-events?project_id=${project_id}&deployment_id=${deployment_id}`,
    callback,
  );
};
