import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as indexHaVyLWlnQxMeta } from "/app/pages/dashboard/[company_slug]/billing/index.vue?macro=true";
import { default as companiesc5evWbgRaWMeta } from "/app/pages/dashboard/[company_slug]/companies.vue?macro=true";
import { default as indexLYraNbpZX6Meta } from "/app/pages/dashboard/[company_slug]/index.vue?macro=true";
import { default as deploymentshtxO8FSBWUMeta } from "/app/pages/dashboard/[company_slug]/projects/[project_ref]/deployments.vue?macro=true";
import { default as indexNl1Z7ZJ1k2Meta } from "/app/pages/dashboard/[company_slug]/projects/[project_ref]/index.vue?macro=true";
import { default as newpXgs5Jt7MJMeta } from "/app/pages/dashboard/[company_slug]/projects/new.vue?macro=true";
import { default as add_45api_45keygl8bbRNDoxMeta } from "/app/pages/dashboard/[company_slug]/settings/add-api-key.vue?macro=true";
import { default as indexsUz6Zk1MenMeta } from "/app/pages/dashboard/[company_slug]/settings/index.vue?macro=true";
import { default as team4I4l49bOm9Meta } from "/app/pages/dashboard/[company_slug]/team.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as docscbEptYMwcSMeta } from "/app/pages/docs.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacy_45policyz5PUdDuYlNMeta } from "/app/pages/legal/privacy-policy.vue?macro=true";
import { default as termsiDH5moXFcLMeta } from "/app/pages/legal/terms.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-billing",
    path: "/dashboard/:company_slug()/billing",
    component: () => import("/app/pages/dashboard/[company_slug]/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-companies",
    path: "/dashboard/:company_slug()/companies",
    component: () => import("/app/pages/dashboard/[company_slug]/companies.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug",
    path: "/dashboard/:company_slug()",
    component: () => import("/app/pages/dashboard/[company_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-projects-project_ref-deployments",
    path: "/dashboard/:company_slug()/projects/:project_ref()/deployments",
    component: () => import("/app/pages/dashboard/[company_slug]/projects/[project_ref]/deployments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-projects-project_ref",
    path: "/dashboard/:company_slug()/projects/:project_ref()",
    component: () => import("/app/pages/dashboard/[company_slug]/projects/[project_ref]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-projects-new",
    path: "/dashboard/:company_slug()/projects/new",
    component: () => import("/app/pages/dashboard/[company_slug]/projects/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-settings-add-api-key",
    path: "/dashboard/:company_slug()/settings/add-api-key",
    component: () => import("/app/pages/dashboard/[company_slug]/settings/add-api-key.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-settings",
    path: "/dashboard/:company_slug()/settings",
    component: () => import("/app/pages/dashboard/[company_slug]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-company_slug-team",
    path: "/dashboard/:company_slug()/team",
    component: () => import("/app/pages/dashboard/[company_slug]/team.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/app/pages/docs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-privacy-policy",
    path: "/legal/privacy-policy",
    component: () => import("/app/pages/legal/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "legal-terms",
    path: "/legal/terms",
    component: () => import("/app/pages/legal/terms.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  }
]