import type { RouteLocationNormalized } from "vue-router";

const isProtected = (to: RouteLocationNormalized) => to.path.startsWith("/dashboard");

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  let user = useUser();
  if (user.value) {
    if (!import.meta.server) useNuxtApp().$setSentryUser(user.value.id);
    return;
  }

  user = await refreshUser();

  if (user.value) {
    if (!import.meta.server) useNuxtApp().$setSentryUser(user.value.id);

    const success = await refreshUserData();
    if (!success) user.value = null;
  }

  if (!user.value && isProtected(to)) {
    const { pathname, search, hash } = useRequestURL();
    return navigateTo(`/login?redirect=${encodeURIComponent(`${pathname}${search}${hash || ""}`)}`);
  }
});
